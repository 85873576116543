import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function DefaultPage() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="auto">Something went wrong....</Col>
      </Row>
    </Container>
  );
}
export default DefaultPage;
