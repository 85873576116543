import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import DefaultPage from "./components/DefaultPage";
import ResetPassword from "./components/ResetPassword";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<DefaultPage />} />
          <Route
            exact
            path="/:lang/:env/:tenant/:token"
            element={<ResetPassword />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
