export const TRANSLATIONS = [
  {
    name: "en",
    translations: {
      "Password Reset": "Password Reset",
      "Please enter your email address and new password.":
        "Please enter your email address and new password.",
      "Email address": "Email address",
      Password: "Password",
      "Confirm Password": "Confirm Password",
      Submit: "Submit",
      "Please provide a valid email": "Please provide a valid email",
      "Password should be 8 characters or more":
        "Password should be 8 characters or more",
      "The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character":
        "The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character",
      "Passwords do not match": "Passwords do not match",
      "Error when trying to reset password, please try again":
        "Error when trying to reset password, please try again",
      "You have successfully updated your password, please return to the app and log in.":
        "You have successfully updated your password, please return to the app and log in.",
    },
  },
  {
    name: "fr",
    translations: {
      "Password Reset": "Réinitialisation du mot de passe",
      "Please enter your email address and new password.":
        "Veuillez SVP entrer votre adresse courriel et votre nouveau mot de passe.",
      "Email address": "Adresse courriel",
      Password: "Mot de passe",
      "Confirm Password": "Confirmez le mot de passe",
      Submit: "Soumettre",
      "Please provide a valid email":
        "Veuillez SVP fournir une adresse courriel valide",
      "Password should be 8 characters or more":
        "Le mot de passe doit comporter 8 caractères ou plus",
      "The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character":
        "Le mot de passe doit contenir au moins 1 minuscule, 1 majuscule et 1 caractère numérique",
      "Passwords do not match": "Les mots de passe ne correspondent pas",
      "Error when trying to reset password, please try again":
        "Erreur lors de la tentative de réinitialisation du mot de passe, veuillez réessayer",
      "You have successfully updated your password, please return to the app and log in.":
        "Vous avez réussi à mettre à jour votre mot de passe, veuillez retourner sur l'application et vous connecter.",
    },
  },
];
